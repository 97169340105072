import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetRawRidersQuery } from "../../Pages/Riders/RiderApi";
import { updateAvailableRiders } from "../slices/locationSlice";

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenWidth;
};

export const useUpdateDriverStatus = () => {
  const dispatch = useDispatch();
  const { currentOrders, riderLocation } = useSelector(
    (state) => state.location
  );
  const [drivers, setDrivers] = useState([]);
  //Once launched, fetch all riders
  const [getRider, { isLoading }] = useLazyGetRawRidersQuery();

  const handleDriverStatus = useCallback(async () => {
    const { data: riders } = await getRider();
    const newRiderOrders = currentOrders?.filter(
      (e) =>
        (e?.requestStatus === "accepted" || e?.requestStatus === "pending") &&
        e?.status !== "delivered" &&
        e?.status !== "cancelled"
    );

    const riderOrderCount = newRiderOrders?.reduce((acc, order) => {
      acc[order.riderId] = (acc[order.riderId] || 0) + 1;
      return acc;
    }, {});

    const updatedDrivers = riderLocation
      ?.map((driver) => {
        const matchedRider = riders?.data?.find(
          (rider) => rider.id === driver.riderId
        );
        const isActive = newRiderOrders?.some(
          (order) => order.riderId === driver.riderId
        );
        const orderCount = riderOrderCount[driver.riderId] || 0;
        return {
          value: driver.riderId,
          label: matchedRider
            ? `${matchedRider.firstName} ${matchedRider.lastName}`
            : "Unknown Rider",
          active: isActive ? true : false,
          location: driver?.location,
          available: orderCount < 2,
        };
      })
      .filter((driver) => driver.available);

    dispatch(updateAvailableRiders(updatedDrivers));
    setDrivers(updatedDrivers);
  }, [currentOrders, riderLocation]);

  const memoizedDrivers = useMemo(() => drivers, [drivers]);

  useEffect(() => {
    if (currentOrders?.length || riderLocation?.length) {
      handleDriverStatus();
    }
  }, [currentOrders, riderLocation, handleDriverStatus]);

  return { drivers: memoizedDrivers, isLoading };
};
