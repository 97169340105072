const PATHS = {
  auth: "auth/api/v1/",
  rider: "rider/api/v1/",
  order: "order/api/v1/",
  location: "location/api/v1/",
  payment: "payment/api/v1/",
};

export default {
  //Auth
  login: {
    url: `${PATHS.auth}auth/login`,
    method: "post",
  },
  validateToken: {
    url: `${PATHS.rider}admin/validate-token`,
    method: "post",
  },
  completeAdminReg: {
    url: `${PATHS.rider}admin/register/complete`,
    method: "put",
  },
  updateAdminProfile: {
    url: `${PATHS.rider}admin/update-profile`,
    method: "put",
  },
  forgotPassword: {
    url: `${PATHS.rider}admin/forgot-password`,
    method: "post",
  },
  verifyOtp: {
    url: `${PATHS.rider}admin/forgot-password/verify-otp`,
    method: "post",
  },
  resendOtp: {
    url: `${PATHS.rider}admin/forgot-password/resend-otp`,
    method: "post",
  },
  resetPassword: {
    url: `${PATHS.rider}admin/reset-password`,
    method: "post",
  },
  refreshToken: {
    url: `${PATHS.auth}auth/refresh`,
    method: "post",
  },

  //Admin User
  createNewAdmin: {
    url: `${PATHS.rider}admin/register`,
    method: "post",
  },
  getAllAdmin: {
    url: `${PATHS.rider}admin`,
    method: "get",
  },
  activateAdmin: (userId) => ({
    url: `${PATHS.rider}admin/activate/${userId}`,
    method: "put",
  }),
  deactivateAdmin: (userId) => ({
    url: `${PATHS.rider}admin/deactivate/${userId}`,
    method: "put",
  }),
  deleteAdmin: (userId) => ({
    url: `${PATHS.rider}admin/remove/${userId}`,
    method: "delete",
  }),
  updateRole: (userId) => ({
    url: `${PATHS.rider}admin/update-role/${userId}`,
    method: "put",
  }),
  getAllRoles: {
    url: `${PATHS.rider}roles`,
    method: "get",
  },
  //Audit Trail
  auditLog: {
    url: `${PATHS.rider}audit-trail`,
    method: "get",
  },

  //Riders
  getAllRiders: (data) => ({
    url: `${PATHS.rider}admin/riders?page=${data.page}`,
    method: "get",
  }),
  getRawRiders: {
    url: `${PATHS.rider}admin/riders?raw=true`,
    method: "get",
  },
  getIndividualRiders: (riderId) => ({
    url: `${PATHS.rider}admin/riders/${riderId}`,
    method: "get",
  }),
  approveRider: (riderId) => ({
    url: `${PATHS.rider}admin/riders/approve/${riderId}`,
    method: "put",
  }),
  declineRider: (riderId) => ({
    url: `${PATHS.rider}admin/riders/decline/${riderId}`,
    method: "put",
  }),
  getRiderHistory: (riderId) => ({
    url: `${PATHS.rider}admin/riders/history/${riderId}`,
    method: "get",
  }),
  getAllRiderRequest: (data) => ({
    url: `${PATHS.rider}rider-request?page=${data.page}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "get",
  }),
  deactivateRider: (riderId) => ({
    url: `${PATHS.rider}admin/riders/deactivate/${riderId}`,
    method: "put",
  }),
  activateRider: (riderId) => ({
    url: `${PATHS.rider}admin/riders/activate/${riderId}`,
    method: "put",
  }),

  //Order
  getAllOrders: (data) => ({
    url: `${PATHS.order}order?page=${data.page}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "get",
  }),
  getIndividualOrder: (id) => ({
    url: `${PATHS.order}order/${id}`,
    method: "get",
  }),
  createOrder: {
    url: `${PATHS.order}order`,
    method: "post",
  },
  getRiderRequest: (id) => ({
    url: `${PATHS.rider}rider-request/${id}`,
    method: "get",
  }),
  cancelOrder: (id) => ({
    url: `${PATHS.order}order/cancel/${id}`,
    method: "put",
  }),
  reassignOrder: {
    url: `${PATHS.rider}rider-request/reassign/rider/order`,
    method: "post",
  },

  //Accounts
  getAccountMetrics: (data) => ({
    url: `${PATHS.order}order/metrics?startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "get",
  }),
  getPeriodicTotalEarnings: (data) => ({
    url: `${PATHS.payment}wallet/transactions/total-earnings?startDate=${data?.startDate}&endDate=${data?.endDate}`,
    method: "get",
  }),
  completePayment: {
    url: `${PATHS.payment}wallet/initiate/payout`,
    method: "post",
  },
  getWallet: {
    url: `${PATHS.payment}wallet`,
    method: "get",
  },

  //Dashboard-Location
  getNearbyRider: (data) => ({
    url: `${PATHS.location}location/nearby/riders?longitude=${data?.lng}&latitude=${data?.lat}`,
    method: "get",
  }),
  getRiderLocation: (id) => ({
    url: `${PATHS.location}location/rider/${id}`,
    method: "get",
  }),
};
